// File: src/components/HsseVision.js

import React from 'react';
import './HsseVision.css';
import Banner from '../banner/Banner.js';
import Header from '../hearder1/Header.js';
import Footer from '../footer/Footer.js';
import Slider1 from '../images/slider/DSC_0909.jpg';
import Partners from '../partners/Partners.js';
import VerticalIcons from '../vertical/Vertical.js';
import logoImage from '../images/logo.JPG';
import { useNavigate } from 'react-router-dom';

const HsseVision = () => {
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
    //setIsMenuOpen(false); // Close mobile menu on navigation
  };
  return (
    <div>
      <Header logoSrc={logoImage} companyName="Kodsonplus Company Ltd"/>
      <Banner 
        image={Slider1} 
        title="Our HSSE Vision" 
        subtitle="We are a leading company in Oil & Gas" 
      />
      <div className="hsse-vision-container">
        <h1 className="hsse-vision-title">
          <span className="highlight">HSSEQ</span> VISION
        </h1>
        <p className="hsse-vision-description">
          In order to achieve continuous improvement, Kodson manages Health, Safety, Security and Environment in an orderly approach. At Kodson, we aim to meet our customer’s expectations and needs in ways that are economically, environmentally and socially responsible. To manage the impact of our operations we have an HSSE and Quality Policy. We are also ISO certified (ISO 9001 for quality and ISO 140001 for environmental management).
        </p>
        <h2 className="hsse-vision-subtitle">OUR VISION</h2>
        <p className="hsse-vision-term">Our HSSE vision is termed '1000'</p>
        <div className="hsse-vision-items">
          <div className="hsse-vision-item">
            <div className="vision-number orange">1</div>
            <p>We aim to be number 1 in our industry and recognized as an industry leader in the way we deliver health, safety, security and environmental performance.</p>
          </div>
          <div className="hsse-vision-item">
            <div className="vision-number black">0</div>
            <p>We operate with 0 fatalities at our workplaces; and</p>
          </div>
          <div className="hsse-vision-item">
            <div className="vision-number orange">0</div>
            <p>We incur 0 serious incidents and occupational injuries.</p>
          </div>
          <div className="hsse-vision-item">
            <div className="vision-number black">0</div>
            <p>We aim to incur 0 fuel quality incident.</p>
          </div>
        </div>
      </div>
      <Partners />
      <VerticalIcons />
      <Footer />
    </div>
  );
};

export default HsseVision;
