import React from 'react';
import '../footer/Footer.css';
import { PiFacebookLogoFill } from "react-icons/pi";
import { TfiTwitterAlt } from "react-icons/tfi";
import { TfiInstagram } from "react-icons/tfi";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h3>About Company</h3>
          <p>Kodson Plus Company Ltd is a haulage and construction company based in Tema registered under the laws of Ghana.</p>
        </div>
        <div className="footer-column">
          <h3>Quick Links</h3>
          <ul className="custom-bullet">
            <li>Home</li>
            <li>About Us</li>
            <li>Gallery</li>
            <li>Contact Us</li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Contact Us</h3>
          <ul className="custom-bullet">
            <li>Email: info@kodsonplus.com</li>
            <li>Phone: +233 501 577846</li>
            <li>Address: P. O. Box CO3186, Tema</li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Follow Us</h3>
          <ul className="social-icons">
            <li><PiFacebookLogoFill /></li>
            <li><TfiTwitterAlt /></li>
            <li><TfiInstagram /></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Kodson Plus Co. Ltd. All rights reserved. Designed by Kodson I.T team</p>
      </div>
    </footer>
  );
}

export default Footer;
