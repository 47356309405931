// src/components/Contact.js
import React, { useState } from 'react';
import './Contact.css';
import Partners from '../partners/Partners.js';
import Banner from '../banner/Banner.js';
import Header from '../hearder1/Header.js';
import Slider1 from '../images/slider/DSC_0909.jpg';
import Footer from '../footer/Footer.js';
import emailjs from 'emailjs-com';
import logoImage from '../images/logo.JPG';

const Contact = () => {
  const [formData, setFormData] = useState({
    from_name: '',
    from_mail: '',
    message: '',
    phone: '',
    consent: false,
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs.sendForm('service_rypke68', 'template_1bji3g9', e.target, 'Xn1g1PoDFCzfkedxy')
      .then((result) => {
        console.log(result.text);
        setSuccessMessage('Your message has been sent successfully!');
        setErrorMessage('');
        setIsSubmitting(false);
      }, (error) => {
        console.log(error.text);
        setErrorMessage('There was an error sending your message. Please try again.');
        setSuccessMessage('');
        setIsSubmitting(false);
      });

    setFormData({
      from_name: '',
      from_mail: '',
      message: '',
      phone: '',
      consent: false,
    });
  };

  return (
    <div>
      <Header logoSrc={logoImage} companyName="Kodsonplus Company Ltd"/>
      <Banner 
        image={Slider1} 
        title="Our Contact Infomations" 
        subtitle="" 
      />
      <div className="contact-container">
        <h2>CONTACT <span>US</span></h2>
        <div className="contact-content">
          <div className="company-info">
            <p><strong>COMPANY INFORMATION</strong></p>
            <p>Tema Community 11 opposite Bengali Hospital, P. O. Box CO3186</p>
            <p>Ghana Post Address: GT-117-2071</p>
            <p>Working hours: Mondays - Fridays 8:00 am - 5pm</p>
             <strong>Phone Lines</strong>
            <p>(+233) 59 163 8818</p>
            <p>(+233) 50 153 4632</p>
          </div>
          <form className="contact-form" onSubmit={handleSubmit}>
            <strong>Leave a message</strong><br/>
            <div className="form-group">
              <label htmlFor="from_name">Your Name *</label>
              <input 
                type="text" 
                id="from_name" 
                name="from_name"
                placeholder="Your Name" 
                value={formData.from_name} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="form-group">
              <label htmlFor="from_mail">Email *</label>
              <input 
                type="email" 
                id="from_mail" 
                name="from_mail" 
                value={formData.from_mail}
                onChange={handleChange} 
                placeholder="Your Email"
                required 
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone</label>
              <input 
                type="text" 
                id="phone" 
                name="phone" 
                value={formData.phone} 
                placeholder="Your phone"
                onChange={handleChange} 
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Your Inquiry</label>
              <textarea 
                id="message" 
                name="message" 
                value={formData.message}
                onChange={handleChange} 
                placeholder="Your inquiry"
              />
            </div>
            <div className="form-group checkbox-group">
              <input 
                type="checkbox" 
                id="consent" 
                name="consent" 
                checked={formData.consent} 
                onChange={handleChange} 
                required 
              />
              <label htmlFor="consent">
                I consent to KODSON PLUS storing my submitted information so they can respond to my inquiry *
              </label>
            </div>
            {!formData.consent && (
              <p className="checkbox-message">Please check the box to enable the submit button.</p>
            )}
            {formData.consent && (
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Sending...' : 'Submit'}
              </button>
            )}
          </form>
          {successMessage && <p className="success-message">{successMessage}</p>}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
        <div className="google-map">
          <iframe
            title="Google Maps Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7940.571565681279!2d-0.027757158750755743!3d5.671758627238986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf8190adc26035%3A0x47a342cf09bb9710!2sKodson%20Plus%20Company%20Limited%20Head%20Office!5e0!3m2!1sen!2sgh!4v1718799354182!5m2!1sen!2sgh"
            allowFullScreen=""
            loading="lazy"
            width="100%"
            height="450"
          ></iframe>
        </div>
      </div>
      <Partners />
      <Footer />
    </div>
  );
};

export default Contact;
