import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import Slider1 from '../images/slider/DSC_0909.jpg';
import Banner from '../banner/Banner.js';
import Header from '../hearder1/Header.js';
import Footer from '../footer/Footer.js';
import Partners from '../partners/Partners.js';
import VerticalIcons from '../vertical/Vertical.js';
import { useNavigate } from 'react-router-dom';
import logoImage from '../images/logo.JPG';
const AboutPage = () => {
    const [expandedSection, setExpandedSection] = useState(null);

    const toggleSection = (section) => {
        setExpandedSection(expandedSection === section ? null : section);
    };

    const renderIcon = (section) => (
        expandedSection === section ? <FaMinus /> : <FaPlus />
    );

    const navigate = useNavigate();
    const handleNavigation = (path) => {
      navigate(path);
      //setIsMenuOpen(false); // Close mobile menu on navigation
    };



    return (
        <div>
            <Header logoSrc={logoImage} companyName="Kodsonplus Company Ltd"/>
            <Banner 
                image={Slider1} 
                title="Welcome to Kodson plus" 
                subtitle="We are a leading company in Oil & Gas" 
            />
            <div className="about-page1">
                <div className="main-content">
                    <div className="content-container1">
                        <div className="story-section1">
                            <h1 className="section-title1">CIVIL ENGINEERING</h1>
                            <p className="story-text1">
                            We use proven methods of coordination and collaboration with strict management of quality, value, schedule and cost. Our engineering and design services range from planning and permitting through preliminary and detailed design to construction and commissioning for all types of projects. Many projects rely on our ability to provide innovative site design that blends the project in with the environment and local topography. We keep up with the latest project regulations and continuously develop our design skills through continuing education and our project experiences. We are committed to providing high quality services within project budget and schedule constraints. We provide functional and constructible designs that meet our clients’ unique and diverse needs while maximizing profit. We remain involved through construction and manage the complex risks and uncertainties related to the final development. Our participation in all of our projects begins from the project understanding to the project approach and finally, to the end product, which is only considered well-done if our Client is thoroughly satisfied with our work product.
                           </p>
                        </div>





                    </div>

                    <div className="quick-links1">
                        <h2>Quick Links</h2>
                        <a onClick={() => handleNavigation('/construction')} className="quick-link1">Construction</a>
                        <a onClick={() => handleNavigation('/civil')} className="quick-link1">Civil Engineering</a>
                        <a onClick={() => handleNavigation('/hauledge')}className="quick-link1">Bulk Oil Haulage</a>
                        <a onClick={() => handleNavigation('/rentals')} className="quick-link1">Equipment Rentals</a>
                    </div>
                </div>
            </div>
            <Partners/>

            {/* Vertical Icons */}
            <VerticalIcons/>

            <Footer/>
        </div>
    );
};

export default AboutPage;
