import React from 'react';
import '../company/CompanyInfo.css';
import Slider1 from '../images/slider/DSC_0909.jpg';
import servicesBackground from '../images/logo.JPG';
import { FaPlane, FaShip, FaCreditCard, FaTools } from 'react-icons/fa';
import { GiMineTruck } from "react-icons/gi";
import { HiTruck } from "react-icons/hi";
import { MdEngineering } from "react-icons/md";
import { RiBuilding2Line } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
const CompanyInfo = () => {
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
    //setIsMenuOpen(false); // Close mobile menu on navigation
  };
  return (
    <div className="kodson-info">
      {/* Who We Are Section */}
      <div className="who-we-are">
        <div className="text">
        <div className="title-line"></div>
          <h2>Welcome</h2>
          <h3>WHO WE ARE</h3>
          <p>
          Kodson Plus Company Ltd is a haulage and construction company based in Tema registered under the laws of Ghana. We have been in operation for about a decade, starting as an equipment rental company and then expanding operations to include bulk oil haulage and civil engineering. We have partnered many highly rated companies in both the Petroleum Haulage and Construction industries. We currently haul on behalf of BOST, UBI and GOIL. Our partners in the construction industry include Desimone, Micheletti and M Barbisotti and in partnership with them we have worked on projects such as the Construction Of Movenpik Hotel Accra, The Renovation Of Ohene Djan Sports Stadium Accra, The Construction Of Ollam Flour Mills, Tema etc.
          </p>
          <button onClick={() => handleNavigation('/about')}>View more</button>
        </div>
        <div className="image">
          <img src={Slider1} alt="Who We Are" />
        </div>
      </div>
      
      {/* Our Services Section */}
      <div className="section our-services1">
        <div className="services-title">
          <div className="title-line"></div>
          <h2>EXPERIENCE</h2>
        </div>
        <h3>OUR SERVICES</h3>
        <div className="services1-grid">
          <div className="service1">
            <GiMineTruck className="icon" />
            <h4>EQUIPMENT RENTALS</h4>
            <p>
            Kodson Plus provides personalized construction equipment rental service that is second to none.
            </p>
            <button onClick={() => handleNavigation('/rentals')}>View more</button>
          </div>
          <div className="service1">
            <HiTruck className="icon" />
            <h4>BULK OIL HALAGE</h4>
            <p>
            Our general haulage division, provides full load, dry freight transport services to manufacturers.
            </p>
            <button onClick={() => handleNavigation('/hauledge')}>View more</button>
          </div>
          <div className="service1">
            <MdEngineering className="icon" />
            <h4>CIVIL ENGINEERING</h4>
            <p>
            We use proven methods of coordination and collaboration with strict management of quality, value, schedule and cost.
            </p>
            <button onClick={() => handleNavigation('/civil')}>View more</button>
          </div>
          <div className="service1">
            <RiBuilding2Line className="icon" />
            <h4>CONSTRUCTION</h4>
            <p>
            We provide clients with representation and sustainability consulting for building projects of all types.
            </p>
            <button onClick={() => handleNavigation('/construction')}>View more</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
