import React from 'react'
import Header from '../hearder1/Header.js';
import Slider from '../slider1/Slider.js';
import Footer from '../footer/Footer.js';
import Partners from '../partners/Partners.js';
import Vertical from '../vertical/Vertical.js';
import CompanyInfo from '../company/CompanyInfo.js';
import logoImage from '../images/logo.JPG';
function Index() {
  return (
    <div>
        <Header logoSrc={logoImage} companyName="Kodsonplus Company Ltd"/>
        <Slider/>
        <CompanyInfo />
        {/* <Home/> */}
        <Partners/>
        <Vertical/>
        <Footer/>
    </div>
  )
}

export default Index