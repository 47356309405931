import React, { useState } from 'react';
import { FaPhone, FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import './Vertical.css';
import { useNavigate } from 'react-router-dom';

function VerticalIcons() {
  const [expandedIcon, setExpandedIcon] = useState(null);

  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };
  return (
    <div className="vertical-icons-container">
      <div className={`icon-container blue-bg ${expandedIcon === 'phone' ? 'expanded' : ''}`} onClick={() => setExpandedIcon(expandedIcon === 'phone' ? null : 'phone')}>
        <FaPhone className="icon-style" />
        {expandedIcon === 'phone' && <span className="contact-text" style={{cursor:'pointer'}}>(+233) 501 577846 or (+233) 591938818</span>}
      </div>
      <div className={`icon-container green-bg ${expandedIcon === 'whatsapp' ? 'expanded' : ''}`} onClick={() => setExpandedIcon(expandedIcon === 'whatsapp' ? null : 'whatsapp')}>
        <FaWhatsapp className="icon-style" />
      </div>
      <div className={`icon-container red-bg ${expandedIcon === 'email' ? 'expanded' : ''}`} onClick={() => setExpandedIcon(expandedIcon === 'email' ? null : 'email')}>
        <FaEnvelope className="icon-style" />
        {expandedIcon === 'email' && <a className="contact-link" onClick={() => handleNavigation('/contact')}>Contact Us</a>}
      </div>
    </div>
  );
}

export default VerticalIcons;
