import React from 'react';
import './Partners.css'; // Import the CSS file for styling
import Bost from '../images/partners/bost.jpg'; // Import partner logos
import Bar from '../images/partners/barbisotti.png';
import De from '../images/partners/de-simone-limited.png';
import Gaso from '../images/partners/gaso.png';
import Mich from '../images/partners/michelette.png';
import San from '../images/partners/santuo.png';
import Puma from '../images/partners/UBI.png';
import Goil from '../images/partners/goil.png';
// Import other partner logos as needed

function Partners() {
  return (
    <div className="partners-container">
      <h2>Our Partners</h2>
      <p>Our clients are the life-source of our business. We believe that integrity in client dealings is an undisputable prerequisite for a successful and sustained business relationship.</p>
      <div className="partners-list">
        <div className="partner-item">
          <img src={Bost} alt="Bost" />
        </div>
        <div className="partner-item">
          <img src={Goil} alt="Goil" />
        </div>
        <div className="partner-item">
          <img src={Bar} alt="Barbisotti" />
        </div>
        <div className="partner-item">
          <img src={De} alt="De Simone Limited" />
        </div>
        <div className="partner-item">
          <img src={Gaso} alt="Gaso" />
        </div>
        <div className="partner-item">
          <img src={Mich} alt="Michelette" />
        </div>
        <div className="partner-item">
          <img src={San} alt="Santuo" />
        </div>
        <div className="partner-item">
          <img src={Puma} alt="UBI" />
        </div>
      </div>
    </div>
  );
}

export default Partners;
