import React from 'react';
import PropTypes from 'prop-types';
import './Banner.css';

const Banner = ({ image, title }) => {
  return (
    <div className="banner" style={{ backgroundImage: `url(${image})` }}>
      <div className="overlay"></div>
      <div className="caption">
        <h1>{title}</h1>
      </div>
    </div>
  );
};

Banner.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Banner;
