// File: src/components/HssePolicy.js

import React from 'react';
import './HssePolicy.css';
import Banner from '../banner/Banner.js';
import Header from '../hearder1/Header.js';
import Footer from '../footer/Footer.js';
import Slider1 from '../images/slider/DSC_0909.jpg';
import Partners from '../partners/Partners.js';
import VerticalIcons from '../vertical/Vertical.js';
import logoImage from '../images/logo.JPG';
import { useNavigate } from 'react-router-dom';
import cert1 from '../images/hsse/cert1.JPG';
const HssePolicy = () => {
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
    //setIsMenuOpen(false); // Close mobile menu on navigation
  };
  return (
    <div>
      <Header logoSrc={logoImage} companyName="Kodsonplus Company Ltd"/>
      <Banner 
        image={Slider1} 
        title="Welcome Our HSSE Policy" 
        subtitle="" 
      />
      <div className="hsse-policy-container">
        <div className="hsse-policy-content">
          <h1 className="title">
            <span className="highlight">HSSEQ</span> POLICY
          </h1>
          <p>
            As a conscientious and responsive transport company, KODSON PLUS is committed to providing our customers with safe and environmentally friendly petroleum and other energy products and services to meet their energy needs.
          </p>
          <p>
            We conduct our business activities in a responsible, honest, transparent and trustworthy manner, which assures the health, safety and security of people, preservation of the environment, quality products and services as well as compliance with statutory and regulatory requirements.
          </p>
          <p>
            To achieve this business ethic and culture, we utilize an integrated HSSEQ Management System that is designed to:
          </p>
          <ol>
            <li>Establish, maintain and improve our Integrated Management System with the latest revision of ISO 9001, Quality Management System, ISO 14001 Environmental Management System and ISO 45001, Occupational Health and Safety management system standard requirements.</li>
            <li>Continually monitor and strive to meet the needs and expectations of our interested parties, including customers, analyse, plan and improve our processes accordingly.</li>
            <li>Regularly monitor and evaluate risks and opportunities associated with the context of our organisation and business processes at least annually and act proactively.</li>
            <li>Achieve and continually improve performance related to the objectives of this policy by leadership commitment and active involvement of as well as consultation with and participation of our professionally qualified, highly skilled, well-trained and motivated staff and their Representatives.</li>
            <li>Always comply with applicable Quality, Environmental, Occupational Health and Safety standards to which GOIL subscribes to and legal requirements.</li>
            <li>Ensure prudent use of resources.</li>
          </ol>
        </div>
        <div className="quick-links">
          <h2>Quick Links</h2>
          <ul>
            <li><a onClick={() => handleNavigation('/hssep')} style={{cursor:'pointer'}}>HSSE POLICY</a></li>
            <li><a onClick={() => handleNavigation('/hssepolicy')} style={{cursor:'pointer'}}>HSSE VISION</a></li>
            <li><a onClick={() => handleNavigation('/hssevision')} style={{cursor:'pointer'}}>HSSEQ PROFILE</a></li>
          </ul>
        </div>
      </div>
      <div className="certificate-section">
        <h2>ISO 9001 AND ISO 14001 CERTIFICATION</h2>
        <p>
          We are proud to have achieved ISO 9001 and ISO 14001 certifications, demonstrating our commitment to quality management and environmental stewardship. These certifications validate our dedication to meeting internationally recognized standards and continuously improving our operations to deliver exceptional products and services while minimizing our environmental impact.
        </p>
        <img src={cert1} alt="ISO Certification" className="certificate-image" />
      </div>

      <Partners />

      {/* Vertical Icons */}
      <VerticalIcons />

      <Footer />
    </div>
  );
};

export default HssePolicy;
