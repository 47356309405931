import React from 'react';
import './Ourteam.css';
import Header from '../hearder1/Header.js';
import Footer from '../footer/Footer.js';
import Partners from '../partners/Partners.js';
import VerticalIcons from '../vertical/Vertical.js'; // Assuming VerticalIcons component is used here
import Safety from'../images/team/Safty.jpg';
import it from'../images/team/I.T.jpg';
import Journey from'../images/team/Akakpovi.jpg';
import Fma from '../images/team/jjjj.jpeg';
import Compliance from '../images/team/Comp.jpeg';
import Secretary from '../images/team/SalmaU.jpg';
import Hr from '../images/team/Hr.jpg';
import Senior from '../images/team/Senior.jpg';
import Maintenance from '../images/team/Ben.jpeg';
import CFOO from '../images/team/CFOO.jpg';
import Opera from '../images/team/Oprations.jpeg';
import Gm from '../images/team/Law.jpg';
import Ceo from'../images/team/Director.JPG';
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import Banner from '../banner/Banner.js';
import logoImage from '../images/logo.JPG';
import Slider1 from '../images/slider/office.jpg';

function Ourteam() {
  const managers = [
    { id: 1, name: 'KODIME AGBEMADOR', position: 'CEO', image: Ceo, facebook: '#', twitter: '#', linkedin: '#' },
    { id: 2, name: 'Dr. LAWRENCE YAO ADDO Ph.D', position: 'GENERAL MANAGER', image: Gm, facebook: '#', twitter: '#', linkedin: '#' },
    { id: 3, name: 'PETER YAMBORIGYA', position: 'OPERATIONS MANAGER ', image: Opera, facebook: '#', twitter: '#', linkedin: '#' },
    //{ id: 4, name: 'Emily Brown', position: 'CFO', image: manager4Img, facebook: '#', twitter: '#', linkedin: '#' },
    { id: 5, name: 'ELISHA SOTTIE', position: 'CFOO', image: CFOO, facebook: '#', twitter: '#', linkedin: '#' },
    { id: 6, name: 'BEJAMIN ASOMANING', position: 'MAINTENANCE MANAGER', image: Maintenance, facebook: '#', twitter: '#', linkedin: '#' },
    { id: 7, name: 'KINGSFORD AMOAH', position: 'SENIOR STATIONS MANAGER', image: Senior, facebook: '#', twitter: '#', linkedin: '#' },
    { id: 8, name: 'SANDRA AGBAMADOR', position: 'HR MANAGER', image: Hr, facebook: '#', twitter: '#', linkedin: '#' },
    { id: 9, name: 'SALMA UMUH SAMBA', position: 'ADMIN MANAGER', image: Secretary, facebook: '#', twitter: '#', linkedin: '#' },
    { id: 11, name: 'JAMES O. AMARTEY', position: 'COMPLIANCE MANAGER', image: Compliance, facebook: '#', twitter: '#', linkedin: '#' },
    { id: 12, name: 'PAUL K. DZINYEDZI', position: 'FUEL STATIONS FINANCE MANAGER', image: Fma, facebook: '#', twitter: '#', linkedin: '#' },
    { id: 13, name: 'RICHMOND AKAKPO', position: 'JOURNEY PLANNER', image: Journey, facebook: '#', twitter: '#', linkedin: '#' },
    { id: 14, name: 'MAXWELL OWUSU', position: 'I.T', image: it, facebook: '#', twitter: '#', linkedin: '#' },
    { id: 15, name: 'EDIIMATA KWAMLA', position: 'SAFETY OFFICER', image: Safety, facebook: '#', twitter: '#', linkedin: '#' },
  ];

  return (
    <div>
      <Header logoSrc={logoImage} companyName="Kodsonplus Company Ltd"/>
      <Banner 
                image={Slider1} 
                title="Welcome to Kodson plus" 
                subtitle="We are a leading company in Oil & Gas" 
            />

      <div className="about-content">                       
         <div className="story-section1">
              <h1 className="section-title1">OUR TEAM</h1>
              <p style={{textAlign:'center'}}>
                  Just as we believe it's important for us to know who you are and how you approach your business, we also believe it's important for you to know who we are and how we approach ours.
                  Here you'll learn about the people, culture and capabilities that make our company unique.
              </p>
          </div>
        <div className="managers-container">
          {managers.map(manager => (
            <div key={manager.id} className="manager-card">
              <img src={manager.image} alt={manager.name} className="manager-image" />
              <h3>{manager.name}</h3>
              <p>{manager.position}</p>
              <div className="social-icons">
                <a href={manager.facebook} target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
                <a href={manager.twitter} target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
                <a href={manager.linkedin} target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Our Partners Section */}
      <Partners/>

      {/* Vertical Icons */}
      <VerticalIcons/>

      <Footer/>
    </div>
  );
}

export default Ourteam;
