// Header.js
import React, { useState, useEffect } from 'react';
import './Header.css';
import { useNavigate } from 'react-router-dom';
import { LuMenuSquare } from "react-icons/lu";
import { MdKeyboardArrowDown } from "react-icons/md";
import {
  PiFacebookLogo,
  PiTwitterLogoDuotone,
  PiInstagramLogoDuotone
} from "react-icons/pi";
import {
  FcFeedback,
  FcCellPhone,
  FcAlarmClock,
  FcExpand,
  FcCollapse
} from "react-icons/fc";
import { VscLocation } from "react-icons/vsc";

const Header = ({ logoSrc, companyName}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
    setIsMenuOpen(false); // Close mobile menu on navigation
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={`App-header ${isScrolled ? 'scrolled' : ''}`}>
      <div className="top-row">
        <button className="header-button" onClick={() => handleNavigation('/hssep')}>ISO 9001:2015</button>
        <button className="header-button" onClick={() => handleNavigation('/hssep')}>ISO 14001:2018</button>
        <button className="header-button" onClick={() => handleNavigation('/contact')}>Contact Us</button>
        <div className='social-icons'>
          <PiFacebookLogo />
          <PiTwitterLogoDuotone />
          <PiInstagramLogoDuotone />
        </div>
        <div className='header-mail'>
          Email: info@kodsonplus.com/kodsontransport@gmail.com
          Tell: (+233) 59 163 8818 or (+233) 50 153 4632
          Working Hours: Mon -Fri: 8:00am - 5:00pm
        </div>
      </div>
      <div className="bottom-row">
        <div className="logo">
          <img src={logoSrc} alt="Company Logo" />
           {/* {companyName} */}
        </div>
        <button className="menu-toggle" onClick={toggleMenu}>
          {isMenuOpen ? <MdKeyboardArrowDown style={{color:'white'}}/> : <LuMenuSquare style={{color:'white'}}/>}
        </button>
        <nav className={`nav ${isMenuOpen ? 'open' : ''}`}>
          <ul>
            <li>
              <a onClick={() => handleNavigation('/index')} style={{cursor:'pointer'}}>HOME</a>
            </li>
            <li className="dropdown">
              <a href="#about">ABOUT US</a>
              <div className="dropdown-content">
                <li onClick={() => handleNavigation('/about')}>Our Story</li>
                <li onClick={() => handleNavigation('/ourteam')}>Management Team</li>
              </div>
            </li>
            <li className="dropdown">
              <a href="#products">SERVICES</a>
              <div className="dropdown-content">
                <li onClick={() => handleNavigation('/construction')}>Construction</li>
                <li onClick={() => handleNavigation('/civil')}>Civil Engineering</li>
                <li onClick={() => handleNavigation('/hauledge')}>Bulk Oil Haulage</li>
                <li onClick={() => handleNavigation('/rentals')}>Equipment Rentals</li>
              </div>
            </li>
            <li className="dropdown">
              <a href="#investor">OUR SISTER COMPANIES</a>
              <div className="dropdown-content">
                <li onClick={() => handleNavigation('/ktc')}>KTC Global</li>
                <li onClick={() => handleNavigation('/kgl')}>KGL</li>
                <li onClick={() => handleNavigation('/lida')}>LIDA GHANA</li>
                <li onClick={() => handleNavigation('/unique')}>UNIQUE TIME</li>
                <li onClick={() => handleNavigation('/just')}>JUST GRACE</li>
              </div>
            </li>
            <li>
              <a onClick={() => handleNavigation('/gallery')} style={{cursor:'pointer'}}>GALLERY</a>
            </li>
            <li>
              <a onClick={() => handleNavigation('/contact')} style={{cursor:'pointer'}}>CONTACT US</a>
            </li>
            <li className="dropdown">
              <a href="#subsidiaries">HSSEQ</a>
              <div className="dropdown-content">
                <li onClick={() => handleNavigation('/hssep')}>HSSEQ PROFILE</li>
                <li onClick={() => handleNavigation('/hssepolicy')}>HSSEQ POLICY</li>
                <li onClick={() => handleNavigation('/hssevision')}>HSSEQ VISION</li>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
