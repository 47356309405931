import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import Slider1 from '../images/slider/DSC_0909.jpg';
import Banner from '../banner/Banner.js';
import Header from '../hearder1/Header.js';
import Footer from '../footer/Footer.js';
import Partners from '../partners/Partners.js';
import VerticalIcons from '../vertical/Vertical.js';
import { useNavigate } from 'react-router-dom';
import logoImage from '../images/logo.JPG';

const AboutPage = () => {
    const [expandedSection, setExpandedSection] = useState(null);

    const toggleSection = (section) => {
        setExpandedSection(expandedSection === section ? null : section);
    };

    const renderIcon = (section) => (
        expandedSection === section ? <FaMinus /> : <FaPlus />
    );

    const navigate = useNavigate();
    const handleNavigation = (path) => {
      navigate(path);
      //setIsMenuOpen(false); // Close mobile menu on navigation
    };

    return (
        <div>
            <Header logoSrc={logoImage} companyName="Kodsonplus Company Ltd"/>
            <Banner 
                image={Slider1} 
                title="Welcome to Kodson plus" 
                subtitle="We are a leading company in Oil & Gas" 
            />
            <div className="about-page1">
                <div className="main-content">
                    <div className="content-container1">
                        <div className="story-section1">
                            <h1 className="section-title1">Bulk Oil Haulage</h1>
                            <p className="story-text1">
                            At Kodson Transport, our general haulage division, providing full load, dry freight transport services to manufacturers, retailers and industry. We pride ourselves as leaders when it comes to specialty bulk liquid transport, Kodson Transport has the experience, equipment and credibility to properly handle the transportation of your oil. This is accomplished by focusing on the most economical avenue for the customer, but also takes into account the necessary safety issues inherent in this unique facet of the trucking industry. By combining those two factors, a win-win situation for clients.
                           </p>
                        </div>





                    </div>

                    <div className="quick-links1">
                        <h2>Quick Links</h2>
                        <a onClick={() => handleNavigation('/construction')} className="quick-link1">Construction</a>
                        <a onClick={() => handleNavigation('/civil')} className="quick-link1">Civil Engineering</a>
                        <a onClick={() => handleNavigation('/hauledge')}className="quick-link1">Bulk Oil Haulage</a>
                        <a onClick={() => handleNavigation('/rentals')} className="quick-link1">Equipment Rentals</a>
                    </div>
                </div>
            </div>
            <Partners/>

            {/* Vertical Icons */}
            <VerticalIcons/>

            <Footer/>
        </div>
    );
};

export default AboutPage;
