import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Index from './component/index/Index.js';
import About from './component/about1/AboutPage.js';
import Contact from './component/contact1/Contact.js';
import Gallery from './component/gallery/Gallery.js';
import Just from './component/just/Just.js';
import Kgl from './component/kgl/Kgl.js';
import Ktc from './component/ktc/Ktc.js';
import Lida from './component/lida/Lida.js';
import Ourteam from './component/ourteam/Ourteam.js';
import Unique from './component/unique/Unique.js';
import Construction from './component/construction/Construction.js';
import Civil from './component/civil/Civil.js';
import Hauledge from './component/hauledge/Hauledge.js';
import Rentals from './component/Rentals/Rentals.js';
import HsseP from './component/hsse/HsseProfile.js';
import HssePolicy from './component/hsse/HssePolicy.js';
import HsseVision from './component/hsse/HsseVision.js';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Navigate to="/index"/>} />
          <Route path="/index" element={<Index/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/gallery" element={<Gallery/>}/>
          <Route path="/just" element={<Just/>}/>
          <Route path="/kgl" element={<Kgl/>}/>
          <Route path="/ktc" element={<Ktc/>}/>
          <Route path="/lida" element={<Lida/>}/>
          <Route path="/ourteam" element={<Ourteam/>}/>
          <Route path="/unique" element={<Unique/>}/>
          <Route path="/construction" element={<Construction/>}/>
          <Route path="/civil" element={<Civil/>}/>
          <Route path="/hauledge" element={<Hauledge/>}/>
          <Route path="/rentals" element={<Rentals/>}/>
          <Route path="/hssep" element={<HsseP/>}/>
          <Route path="/hssepolicy" element={<HssePolicy/>}/>
          <Route path="/hssevision" element={<HsseVision/>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
