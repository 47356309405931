import React, { useState, useEffect } from 'react';
import './Slider.css';
import Slider1 from '../images/slider/DSC_0909.jpg';
import Ment from '../images/gallery/P6.jpeg';
import Entrance from '../images/gallery/P2.jpeg';
import Award1 from '../images/slider/A1.jpg';
import Award2 from '../images/slider/A2.jpg';
import Award3 from '../images/slider/A3.JPG';
import Office from '../images/slider/office.jpg';
import NewsTicker from '../news/NewsTicker.js';
import Award4 from '../images/2023/L7.jpeg';
import Award5 from '../images/2023/L16.jpeg';
const images = [
  { url: Slider1, text: 'Some Of Our Goil Trucks' },
  { url: Ment, text: 'Some Of Our BOST Operational Trucks' },
  { url: Entrance, text: 'A Wide View Of Our Yard' },
  { url: Award1, text: '2021 Oil & Gas Award winner' },
  { url: Award2, text: '2022 Oil & Gas Award winner' },
  { url: Award3, text: '2023 Oil & Gas Award winner' },
  { url: Office, text: 'Our Corporate Head Office' },
  { url: Award4, text: 'All GOGA Award Winners 2023' },
  { url: Award5, text: 'Receiving The Entreprenuer Of The Year Award ' },
  // Add more images and text here
];

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 8000); // Change image every 8 seconds

    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="slider-container">
      <div className="slider">
        {images.map((image, index) => (
          <div
            key={index}
            className={`slide ${index === currentIndex ? 'active' : ''}`}
            style={{ backgroundImage: `url(${image.url})` }}
          >
            <div className="slide-text">{image.text}</div>
            
          </div>
        ))}
        <button className="prev" onClick={prevSlide}>❮</button>
        <button className="next" onClick={nextSlide}>❯</button>
      </div>
      <NewsTicker/>
    </div>
  
  );
};

export default Slider;
