// Gallery.js
import React, { useState } from 'react';
import Header from '../hearder1/Header.js';
import Footer from '../footer/Footer.js';
import Partners from '../partners/Partners.js';
import VerticalIcons from '../vertical/Vertical.js';
import Slider1 from '../images/slider/L6.jpeg';
import g1 from '../images/gallery/P1.jpeg';
import g2 from '../images/gallery/P2.jpeg';
import g3 from '../images/gallery/P3.jpeg';
import g4 from '../images/gallery/P4.jpeg';
import g6 from '../images/gallery/P5.jpeg';
import g7 from '../images/gallery/P6.jpeg';
import g8 from '../images/gallery/P7.jpeg';

import g9 from '../images/awards/0A4A0429.jpg';
import g10 from '../images/awards/0A4A0443.jpg';
import g11 from '../images/awards/0A4A0509.jpg';
import g12 from '../images/awards/DSC_3487.JPG';
import g13 from '../images/awards/DSC_3566.JPG';

import g13_2021 from '../images/awards/DSC_3566.JPG'; 
import g13_2022 from '../images/awards/0A4A0443.jpg'; 
import g13_2023 from '../images/awards/DSC_3487.JPG'; 

import g14 from '../images/projects/p1.jpg';
import g15 from '../images/projects/p2.jpg';
import g16 from '../images/projects/p3.jpg';
import g17 from '../images/projects/p4.jpg';
import g18 from '../images/projects/p5.jpg';
import g19 from '../images/projects/p6.jpg';
import g20 from '../images/projects/p7.jpg';

import g21 from '../images/earthMoving/e1.jpeg';
import g22 from '../images/earthMoving/e2.jpeg';
import g23 from '../images/earthMoving/e3.jpeg';
import g24 from '../images/earthMoving/e4.jpeg';
import g25 from '../images/earthMoving/e5.jpeg';
import g26 from '../images/earthMoving/e6.jpeg';
import g27 from '../images/earthMoving/e7.jpg';

import g28 from '../images/Spill drill/7.jpg';
import g29 from '../images/Spill drill/10.jpg';
import g30 from '../images/Spill drill/11.jpg';
import g31 from '../images/Spill drill/12.jpg';
import g32 from '../images/Spill drill/13.jpg';
import g33 from '../images/Spill drill/14.jpg';
import g34 from '../images/Spill drill/15.jpg';

// vids
import vid1 from '../images/vids/V1.mp4';
import vid2 from '../images/vids/V2.mp4';

//2021
import g37 from '../images/2021/A3.JPG';
import g38 from '../images/2021/A4.JPG';
import g39 from '../images/2021/A5.JPG';
import g40 from '../images/2021/A6.JPG';
import g41 from '../images/2021/A7.JPG';
import g42 from '../images/2021/A8.JPG';
import g43 from '../images/2021/A9.JPG';

//2022
import g44 from '../images/2022/A1.jpg';
import g45 from '../images/2022/A2.jpg';
import g46 from '../images/2022/A3.jpg';
import g47 from '../images/2022/A4.jpg';
import g48 from '../images/2022/A5.jpg';
import g49 from '../images/2022/A6.jpg';
import g50 from '../images/2022/A7.jpg';
import g51 from '../images/2022/A8.jpg';

//2023
import g52 from '../images/2023/L4.jpeg';
import g53 from '../images/2023/L6.jpeg';
import g54 from '../images/2023/L7.jpeg';
import g55 from '../images/2023/L11.jpeg';
import g56 from '../images/2023/L12.jpeg';
import g57 from '../images/2023/L15.jpeg';
import g58 from '../images/2023/L16.jpeg';
import g59 from '../images/2023/L17.jpeg';
import g60 from '../images/2023/L23.jpeg';

import { MdOutlineZoomOutMap, MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { GrClose } from "react-icons/gr";
import './Gallery.css';
import Banner from '../banner/Banner.js';
import logoImage from '../images/logo.JPG';

function Gallery() {
  const [currentCategory, setCurrentCategory] = useState('all');
  const [showSlideshow, setShowSlideshow] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const galleryImages = {
    all: [
      { id: 14, src: g37 },
      { id: 33, src: g38 },
      { id: 34, src: g39 },
      { id: 35, src: g40 },
      { id: 36, src: g41 },
      { id: 37, src: g42 },
      { id: 38, src: g43 },

      { id: 15, src: g44 },
      { id: 39, src: g45 },
      { id: 40, src: g46 },
      { id: 41, src: g47 },
      { id: 42, src: g48 },
      { id: 43, src: g49 },
      { id: 44, src: g50 },
      { id: 45, src: g51 },

      { id: 46, src: g52 },
      { id: 47, src: g53 },
      { id: 48, src: g54 },
      { id: 49, src: g55 },
      { id: 50, src: g56 },
      { id: 51, src: g57 },
      { id: 52, src: g58 },
      { id: 53, src: g59 },
      { id: 54, src: g60 },

      { id: 9, src: g9 },
      { id: 10, src: g10 },
      { id: 11, src: g11 },
      { id: 12, src: g12 },
      { id: 13, src: g13 },
      { id: 14, src: g13_2021 },
      { id: 15, src: g13_2022 },
      { id: 16, src: g13_2023 },

      { id: 17, src: g21 },
      { id: 18, src: g22 },
      { id: 19, src: g23 },
      { id: 20, src: g24 },
      { id: 21, src: g25 },
      { id: 22, src: g26 },
      { id: 23, src: g27 },

      { id: 24, src: g14 },
      { id: 25, src: g15 },
      { id: 26, src: g16 },
      { id: 27, src: g17 },
      { id: 28, src: g18 },
      { id: 29, src: g19 },
      { id: 30, src: g20 },

      { id: 31, src: g28 },
      { id: 32, src: g29 },
      { id: 33, src: g30 },
      { id: 34, src: g31 },
      { id: 35, src: g32 },
      { id: 36, src: g33 },
      { id: 37, src: g34 },
    ],
    nature: [
      { id: 1, src: g1 },
      { id: 2, src: g2 },
      { id: 3, src: g3 },
      { id: 4, src: g4 },
      { id: 5, src: g6 },
      { id: 6, src: g7 },
      { id: 7, src: g8 },
    ],
    architecture: [
      { id: 31, src: g28 },
      { id: 32, src: g29 },
      { id: 33, src: g30 },
      { id: 34, src: g31 },
      { id: 35, src: g32 },
      { id: 36, src: g33 },
      { id: 37, src: g34 },
    ],
    people: [
      { id: 17, src: g21 },
      { id: 18, src: g22 },
      { id: 19, src: g23 },
      { id: 20, src: g24 },
      { id: 21, src: g25 },
      { id: 22, src: g26 },
      { id: 23, src: g27 },
    ],
    Awards: [
      { id: 14, src: g37 },
      { id: 33, src: g38 },
      { id: 34, src: g39 },
      { id: 35, src: g40 },
      { id: 36, src: g41 },
      { id: 37, src: g42 },
      { id: 38, src: g43 },

      { id: 15, src: g44 },
      { id: 39, src: g45 },
      { id: 40, src: g46 },
      { id: 41, src: g47 },
      { id: 42, src: g48 },
      { id: 43, src: g49 },
      { id: 44, src: g50 },
      { id: 45, src: g51 },

      { id: 46, src: g52 },
      { id: 47, src: g53 },
      { id: 48, src: g54 },
      { id: 49, src: g55 },
      { id: 50, src: g56 },
      { id: 51, src: g57 },
      { id: 52, src: g58 },
      { id: 53, src: g59 },
      { id: 54, src: g60 },
    ],
    awards2021: [
      { id: 14, src: g37 },
      { id: 33, src: g38 },
      { id: 34, src: g39 },
      { id: 35, src: g40 },
      { id: 36, src: g41 },
      { id: 37, src: g42 },
      { id: 38, src: g43 },
    ],
    awards2022: [
      { id: 15, src: g44 },
      { id: 39, src: g45 },
      { id: 40, src: g46 },
      { id: 41, src: g47 },
      { id: 42, src: g48 },
      { id: 43, src: g49 },
      { id: 44, src: g50 },
      { id: 45, src: g51 },
    ],
    awards2023: [
      { id: 46, src: g52 },
      { id: 47, src: g53 },
      { id: 48, src: g54 },
      { id: 49, src: g55 },
      { id: 50, src: g56 },
      { id: 51, src: g57 },
      { id: 52, src: g58 },
      { id: 53, src: g59 },
      { id: 54, src: g60 },
    ],
    projects: [
      { id: 24, src: g14 },
      { id: 25, src: g15 },
      { id: 26, src: g16 },
      { id: 27, src: g17 },
      { id: 28, src: g18 },
      { id: 29, src: g19 },
      { id: 30, src: g20 },
    ],
    vids: [
      { id: 31, src: vid1 },
      { id: 32, src: vid2 },
    ],
  };

  const handleCategoryChange = (category) => {
    setCurrentCategory(category);
  };

  const handleOpenSlideshow = (index) => {
    setSelectedImageIndex(index);
    setShowSlideshow(true);
  };

  const handleCloseSlideshow = () => {
    setShowSlideshow(false);
  };

  const handlePreviousImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex - 1 + imagesToShow.length) % imagesToShow.length);
  };

  const handleNextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % imagesToShow.length);
  };

  const imagesToShow = galleryImages[currentCategory] || galleryImages.all;

  return (
    <div>
      <Header logoSrc={logoImage} companyName="Kodsonplus Company Ltd"/>
      <Banner 
          image={Slider1} 
          title="Welcome to Our Gallery" 
          subtitle="" 
      />

      <div>
        <ul className='g-ul'>
          <li onClick={() => handleCategoryChange('all')}>All</li>
          <li onClick={() => handleCategoryChange('projects')}>Projects</li>
          <li onClick={() => handleCategoryChange('people')}>Earth Moving Vehicles</li>
          <li onClick={() => handleCategoryChange('nature')}>Petroleum Haulage</li>
          <li onClick={() => handleCategoryChange('architecture')}>Spill Drill</li>
          <li className="dropdown">
            <span>Oil And Gas Awards</span>
            <ul className="dropdown-content">
              <li onClick={() => handleCategoryChange('awards2021')}>Awards 2021</li>
              <li onClick={() => handleCategoryChange('awards2022')}>Awards 2022</li>
              <li onClick={() => handleCategoryChange('awards2023')}>Awards 2023</li>
            </ul>
          </li>
          <li onClick={() => handleCategoryChange('vids')}>Videos</li>
        </ul>
      </div>

      {currentCategory !== 'vids' && (
        <div className="gallery-container">
          {imagesToShow.map((item, index) => (
            <div key={item.id} className="gallery-item">
              <img src={item.src} alt={`Image ${item.id}`} className="gallery-image" onClick={() => handleOpenSlideshow(index)} />
              <div className="g-overlay"></div>
              <MdOutlineZoomOutMap className="zoom-out-icon" onClick={() => handleOpenSlideshow(index)} />
            </div>
          ))}
        </div>
      )}

      {currentCategory === 'vids' && (
        <div className="video-container">
          {imagesToShow.map((item) => (
            <div key={item.id} className="video-item">
              <video controls className="gallery-video">
                <source src={item.src} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          ))}
        </div>
      )}

      {showSlideshow && imagesToShow[selectedImageIndex] && currentCategory !== 'vids' && (
        <div className="slideshow-modal">
          <div className="slideshow-content">
            <img src={imagesToShow[selectedImageIndex].src} alt={`Image ${imagesToShow[selectedImageIndex].id}`} />
            <MdKeyboardArrowLeft className="slideshow-control left" onClick={handlePreviousImage} style={{ color: 'orangered' }} />
            <MdKeyboardArrowRight className="slideshow-control right" onClick={handleNextImage} style={{ color: 'orangered' }} />
            <GrClose className="close-icon" onClick={handleCloseSlideshow} style={{ color: 'orangered', width: '30px', height: '30px' }} />
          </div>
        </div>
      )}

      <Partners />
      <VerticalIcons />
      <Footer />
    </div>
  );
}

export default Gallery;
