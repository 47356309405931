import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import Slider1 from '../images/slider/DSC_0909.jpg';
import Banner from '../banner/Banner.js';
import Header from '../hearder1/Header.js';
import Footer from '../footer/Footer.js';
import Partners from '../partners/Partners.js';
import VerticalIcons from '../vertical/Vertical.js';
import { useNavigate } from 'react-router-dom';
import logoImage from '../images/logo.JPG';

const AboutPage = () => {
    const [expandedSection, setExpandedSection] = useState(null);

    const toggleSection = (section) => {
        setExpandedSection(expandedSection === section ? null : section);
    };

    const renderIcon = (section) => (
        expandedSection === section ? <FaMinus /> : <FaPlus />
    );

    const navigate = useNavigate();
    const handleNavigation = (path) => {
      navigate(path);
      //setIsMenuOpen(false); // Close mobile menu on navigation
    };

    return (
        <div>
            <Header logoSrc={logoImage} companyName="Kodsonplus Company Ltd"/>
            <Banner 
                image={Slider1} 
                title="Welcome to Kodson plus" 
                subtitle="We are a leading company in Oil & Gas" 
            />
            <div className="about-page1">
                <div className="main-content">
                    <div className="content-container1">
                        <div className="story-section1">
                            <h1 className="section-title1">CONSTRUCTION</h1>
                            <p className="story-text1">
                            Kodson Transport provides clients with representation and sustainability consulting for building projects of all types. Our philosophy is to exceed the client’s expectations by viewing construction as a service; to listen, understand, plan, build a team and implement the plan. We set ourselves apart from other builders with our leadership and determination. We work with clients as a trusted advisor to provide unbiased recommendations that will help accomplish construction and green building goals – from design through construction close-out. Regardless of the approach, each project receives an unsurpassed commitment to quality from inception to completion due to our unique, proprietary project control system. Each employee is indoctrinated in the system whereby checks and balances are implemented to ensure flawless execution and delivery of construction services. Relying on the experience and knowledge attained from over 10 years of construction services and general contracting service, we understand the diversified delivery methods and needs of every client.
                            </p>
                        </div>





                    </div>

                    <div className="quick-links1">
                        <h2>Quick Links</h2>
                        <a onClick={() => handleNavigation('/construction')} className="quick-link1">Construction</a>
                        <a onClick={() => handleNavigation('/civil')} className="quick-link1">Civil Engineering</a>
                        <a onClick={() => handleNavigation('/hauledge')}className="quick-link1">Bulk Oil Haulage</a>
                        <a onClick={() => handleNavigation('/rentals')} className="quick-link1">Equipment Rentals</a>
                    </div>
                </div>
            </div>
            <Partners/>

            {/* Vertical Icons */}
            <VerticalIcons/>

            <Footer/>
        </div>
    );
};

export default AboutPage;
