// src/components/HsseProfile/HsseProfile.js
import React from 'react';
import './HsseProfile.css';
import Banner from '../banner/Banner.js';
import Header from '../hearder1/Header.js';
import Footer from '../footer/Footer.js';
import Slider1 from '../images/slider/DSC_0909.jpg';
import Partners from '../partners/Partners.js';
import VerticalIcons from '../vertical/Vertical.js';
import logoImage from '../images/logo.JPG';

import sunday from '../images/hsse/mon.jpeg';
import monday from '../images/hsse/moday.jpeg';

import cert1 from '../images/hsse/cert1.JPG';
import cert2 from '../images/hsse/cert2.jpg';

const HsseProfile = () => {
  return (
    <div>

    <Header logoSrc={logoImage} companyName="Kodsonplus Company Ltd"/>
    <Banner 
        image={Slider1} 
        title="Welcome to Kodson Plus Ltd" 
        subtitle="We are a leading company in Oil & Gas" 
    />

    <div className="hsse-profile">
      <h1>HSSEQ</h1>

      <section className="hsseq-story">
        <h2>OUR HSSEQ STORY</h2>
        <p>
          At Kodson Plus Co.ltd, our HSSEQ (Health, Safety, Security, Environment and Quality) journey has been one of unwavering
          commitment and continuous improvement. We have embraced HSSE as an integral part of our corporate culture,
          recognizing that the well-being of our people, the protection of our people and assets, and the preservation
          of the environment are essential for our long-term success and sustainable growth.
        </p>
      </section>

      <section className="hsse-excellence">
        <h2>FOUNDATIONS OF HSSE EXCELLENCE</h2>
        <p>We have established a strong foundation for HSSE excellence, guided by the following principles:</p>
        <ol>
          <li>
            <strong>Leadership Commitment:</strong> Our leadership is fully committed to HSSE and serves as role models
            for driving a culture of safety, responsibility, and environmental stewardship throughout the organization.
            They prioritize HSSE in strategic decision-making, resource allocation, and performance evaluations.
          </li>
          <li>
            <strong>Employee Engagement:</strong> We actively engage our employees through communication, training, and
            empowerment, fostering a sense of ownership and personal commitment to HSSE in their daily activities.
          </li>
          <li>
            <strong>Safety as everyone's business:</strong> We firmly believe that safety is not just the responsibility
            of a single department or a select group of individuals, it is the responsibility of each and every person
            within our organization. We embrace the philosophy of "safety as everyone's business" to create a culture
            where safety is ingrained in every aspect of our operations. We empower and encourage all employees to
            actively participate in maintaining a safe and secure work environment.
          </li>
          <li>
            <strong>One Team with a common Goal:</strong> We believe in the power of unity and collaboration. We foster
            a spirit of togetherness, recognizing that we are one team working towards a common goal. Our success is
            built on the collective efforts and shared commitment of our employees, stakeholders, and partners.
          </li>
        </ol>
      </section>

      <div className="hsse-vision-container">
        <h1 className="hsse-vision-title">
          <span className="highlight">HSSEQ</span> VISION
        </h1>
        <p className="hsse-vision-description">
          In order to achieve continuous improvement, Kodson manages Health, Safety, Security and Environment in an orderly approach. At Kodson, we aim to meet our customer’s expectations and needs in ways that are economically, environmentally and socially responsible. To manage the impact of our operations we have an HSSE and Quality Policy. We are also ISO certified (ISO 9001 for quality and ISO 140001 for environmental management).
        </p>
        <h2 className="hsse-vision-subtitle">OUR VISION</h2>
        <p className="hsse-vision-term">Our HSSE vision is termed '1000'</p>
        <div className="hsse-vision-items">
          <div className="hsse-vision-item">
            <div className="vision-number orange">1</div>
            <p>We aim to be number 1 in our industry and recognized as an industry leader in the way we deliver health, safety, security and environmental performance.</p>
          </div>
          <div className="hsse-vision-item">
            <div className="vision-number black">0</div>
            <p>We operate with 0 fatalities at our workplaces; and</p>
          </div>
          <div className="hsse-vision-item">
            <div className="vision-number orange">0</div>
            <p>We incur 0 serious incidents and occupational injuries.</p>
          </div>
          <div className="hsse-vision-item">
            <div className="vision-number black">0</div>
            <p>We aim to incur 0 fuel quality incident.</p>
          </div>
        </div>
      </div>

      <section className="health-wellbeing">
        <h2>HEALTH AND WELLBEING</h2>
        <p>
          The health and well-being of our employees are paramount to us. We prioritize the following initiatives to ensure a safe and healthy work environment:
        </p>
        <div className="health-wellbeing-content">
          <div className="health-wellbeing-item">
            <img src={sunday} alt="Occupational Health Programs" />
            <p><strong>Occupational Safety Meetings:</strong> We have robust occupational Safety Meetings in place to safeguard the well-being of our employees. These programs encompass regular safety, road safety, and health awareness campaigns aimed at promoting safety and healthy lifestyle.</p>
          </div>
          <div className="health-wellbeing-item">
            <img src={monday} alt="Wellness Initiatives" />
            <p><strong>Wellness Initiatives:</strong> We recognize the importance of fostering a work-life balance and supporting the holistic well-being of our employees. We offer a range of wellness initiatives, such as fitness programs, stress management workshops, and access to counseling services to support their physical, mental, and emotional health.</p>
          </div>
        </div>
      </section>

      <section className="iso-certification">
        <h2>ISO 9001:2015 AND ISO 14001:2018 CERTIFICATION</h2>
        <p>
          We are proud to have achieved ISO 9001:2015 and ISO 14001:2018 certifications, demonstrating our commitment to quality management and environmental stewardship. These certifications validate our dedication to meeting internationally recognized standards and continuously improving our operations to deliver exceptional products and services while minimizing our environmental impact.
        </p>
        <img src={cert1} alt="ISO Certification" className="iso-certification-image" />
        <img src={cert2} alt="ISO Certification" className="iso-certification-image" />
      </section>
    </div>
    <Partners/>

      {/* Vertical Icons */}
      <VerticalIcons/>

      <Footer/>
    </div>
  );
};

export default HsseProfile;
