import React, { useState } from 'react';
import './AboutPage.css';
import { FaPlus, FaMinus } from 'react-icons/fa';
import Slider1 from '../images/slider/DSC_0909.jpg';
import Banner from '../banner/Banner.js';
import Header from '../hearder1/Header.js';
import Footer from '../footer/Footer.js';
import Partners from '../partners/Partners.js';
import VerticalIcons from '../vertical/Vertical.js';
import logoImage from '../images/logo.JPG';
import { useNavigate } from 'react-router-dom';
const AboutPage = () => {
    const [expandedSection, setExpandedSection] = useState(null);

    const toggleSection = (section) => {
        setExpandedSection(expandedSection === section ? null : section);
    };

    const renderIcon = (section) => (
        expandedSection === section ? <FaMinus /> : <FaPlus />
    );
    const navigate = useNavigate();
    const handleNavigation = (path) => {
      navigate(path);
      //setIsMenuOpen(false); // Close mobile menu on navigation
    };

    return (
        <div>
            <Header logoSrc={logoImage} companyName="Kodsonplus Company Ltd"/>
            <Banner 
                image={Slider1} 
                title="Welcome to Kodson plus" 
                subtitle="We are a leading company in Oil & Gas" 
            />
            <div className="about-page1">
                <div className="main-content">
                    <div className="content-container1">
                        <div className="story-section1">
                            <h1 className="section-title1">OUR STORY</h1>
                            <p className="story-text1">
                                Kodson Transport Company Ltd is a haulage and construction company based in Tema registered under the laws of Ghana.
                                We have been in operation for about a decade, starting as an equipment rental company and then expanding operations to include bulk oil haulage and civil engineering.
                                We have partnered many highly rated companies in both the Petroleum Haulage and Construction industries. We currently haul on behalf of BOST, UBI and GOIL. Our partners in the construction industry include Desimone, Micheletti and M Barbisotti and in partnership with them we have worked on projects such as the Construction Of Movenpik Hotel Accra, The Renovation Of Ohene Djan Sports Stadium Accra, The Construction Of 011am Flour Mills, Tema etc.
                                In 2011 we partnered a major player in the petroleum haulage industry and that commenced our petroleum haulage unit. We started with six (6) trucks and within two years we have acquired forty two (42) additional brand new vehicles.
                                The company employs one hundred and twenty-five/125 workers made of Managers, Safety Officers, Maintenance Officers, Professional Drivers, Supervisors, Driver's Mates, Security Officers and Administrative Support.
                                We differentiate ourselves by partnering our clients in ways that ensures that they derive the best value for their investments.
                            </p>
                        </div>

                        <div className="mission-vision-section1">
                            <div className="mission-box1">
                                <h2>Our Mission</h2>
                                <p>
                                    Kodson Plus Company Limited has the Mission "To create value for our shareholders by delivering value to our customers, employees and communities safely through the adherence to our core values of safety, transparency and partnership".
                                </p>
                            </div>
                            <div className="vision-box1">
                                <h2>Our Vision</h2>
                                <p>
                                    Kodson Plus Company Limited seeks to become a first class petroleum haulage company of choice and a leader in Ghana and the West Africa sub-region, delivering unequalled value to its stakeholders in a socially and environmentally sustainable manner. Specifically, the vision of KPCL “Is to become the most sought-after haulage and construction firm throughout Africa”.
                                </p>
                            </div>
                        </div>

                        <div className="core-values-section1">
                            <h2>Core Values</h2>
                            <p>
                            Kodson Plus Company is operated under five core values which continues to direct and shape our focus. Our values endear us to look beyond profitability with a focus on sustainability and societal good.
                            </p>
                            <div className="core-values-container">
                                <div className="core-value-box core-value-box-good">
                                    <h3>GOOD</h3>
                                    <ul>
                                        <li>Good Energy</li>
                                        <li>Quality Products and Services</li>
                                        <li>Environmentally Friendly Products</li>
                                        <li>Customer Focused</li>
                                        <li>Safety in mind</li>
                                        <li>Fuel the nation</li>
                                    </ul>
                                </div>
                                <div className="core-value-box core-value-box-originality">
                                    <h3>ORIGINALITY</h3>
                                    <ul>
                                        <li>Innovation</li>
                                        <li>Proudly Ghanaian</li>
                                        <li>Creativity</li>
                                        <li>Distinctive</li>
                                    </ul>
                                </div>
                                <div className="core-value-box core-value-box-integrity">
                                    <h3>INTEGRITY</h3>
                                    <ul>
                                        <li>Openness</li>
                                        <li>Fairness</li>
                                        <li>Honesty</li>
                                        <li>Credibility</li>
                                        <li>Commitment</li>
                                        <li>Ethical</li>
                                    </ul>
                                </div>
                                <div className="core-value-box core-value-box-leadership">
                                    <h3>LEADERSHIP</h3>
                                    <ul>
                                        <li>Growth</li>
                                        <li>Industry Leadership</li>
                                        <li>Socially Responsible</li>
                                        <li>Staff Development</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="collapsible-sections1">
                            <div className="section1" onClick={() => toggleSection('subsidiaries')}>
                                <h3>WHO WE ARE {renderIcon('subsidiaries')}</h3>
                                {expandedSection === 'subsidiaries' && (
                                    <p>
                                        
KODSON PLUS COMPANY LIMITED, hereinafter called KPCL was incorporated as a limited liability company in the year 2010 and has since grown to expand its market share in the petroleum downstream sector as a haulage company. The company has maintained the best of quality human resource focused on the provision of efficient, high quality transportation of petroleum and allied products to our cherish clients.

The company in recent years driven by our unrelenting focused on customer satisfaction, health, safety and environmental sustainability, and the need to make a positive and sustainable impact on society has taken deliberate steps to improve upon our systems and processes in all operational areas.

We seek to become the Primus inter-paris (first among equals) in the downstream petroleum sector by taken a leading role for others to follow. We are happy to announce being the first petroleum downstream company to be ISO 9001:2015 and 45001:2018 certified in Ghana. This is part of our quest to subject our operations to the best of standard measurement whilst assuring our clients of our resolve to provide them with unapparelled service.
                                    </p>
                                )}
                            </div>
                            <div className="section1" onClick={() => toggleSection('retail')}>
                                <h3>WHAT WE DO {renderIcon('retail')}</h3>
                                {expandedSection === 'retail' && (
                                    <p>
                                        We are specialized in the provision of excellent haulage services to our clients in the petroleum downstream sector. We do this by using the best technological applications and standard vehicles that deliver beyond client’s expectations.
                                    </p>
                                )}
                            </div>
                            <div className="section1" onClick={() => toggleSection('quality')}>
                                <h3>HEALTH SAFETY ENIVIRONMENT & QUALITY (HSEQ) COMMITMENT {renderIcon('quality')}</h3>
                                {expandedSection === 'quality' && (
                                    <p>
                                        KPCL has a well-developed Road Transport HSSE Management System and undertakes periodic operational internal and external audits through its external ISO Auditors and Goil Groups’ HSSE team to ensure this management system is being adhered to and is up to date with industry best practices.
                                        Our HESQ management is structured along the lines of the ISO 45001:2018 and 9001 and 2015 requirement. This ensures a greater commitment to client satisfaction and operational safety.
                                        KPCL ensures consistent drug and alcoholic testing to check abuse for all staff members. We ensure constant relatable education through meetings, toolbox talks and personal coaching from Operations, HSSE department and the Driver Mentor. Drivers also have operational handbooks as guides as well as well properly displayed company polices on HSSE practices at vantage points in various operational areas.
                                        KPCL has a very well trained and dedicated HSSE Manager who is supported by a committed steering committee and able safety team. We have a good emergency response procedure and with clear guidelines on handling majority of all possible emergency scenarios.
                                        We segregate loaded trucks for specifically designated parking areas that are safe and bonded to minimize environmental exposure risks.
                                        All staff is encouraged to use the right Personal Protective Equipment (PPE) for the right job, we do not condone PPE misuse in anyway and the offence is stringently punishable even up to dismissal.
                                        KPCL keeps follow-up records on potential incidents and unsafe conditions to inform future route mapping and for journey management purposes.
                                    </p>
                                )}
                            </div>
                            <div className="section1" onClick={() => toggleSection('products')}>
                                <h3>FLEET MANAGEMENT {renderIcon('products')}</h3>
                                {expandedSection === 'products' && (
                                    <p>
                                        KPCL operates a minimum fleet strength of over 62 bulk vehicles most of which are fairly new. Fleet performance is monitored by a comprehensive Haulage Management System which ensures that the trucks are professionally maintained in line with company and industry HSSE standards and that KPCL has adequate spare drivers as back-up at all material times.
We have installed state of the art vehicle tracking system which enables for live tracking and driving behaviour of our drivers. This over the years have helped reduced the rate of incidents and accidents on the roads and also shaped driver attitudes to safety.
As part of our fleet management process, we have developed a robust maintenance plan and procedures which ensure vehicles are on top conditions at all times. We are part of the maintenance process conduct:
<ul>
   <li>Daily Inspections.</li> 
   <li>Monthly preventive inspections</li> 
   <li>Routine maintenance, servicing and parts replacement</li> 
   <li>Bi-annual inspections.</li> 
</ul>
At KPCL our goal is to be the transporter of excellence, implementing systems that allow for minimal error and ensure best practice is a daily continuous occurrence.
                                    </p>
                                )}
                            </div>
                            <div className="section1" onClick={() => toggleSection('payment')}>
                                <h3>DRIVER MANAGEMENT {renderIcon('payment')}</h3>
                                {expandedSection === 'payment' && (
                                    <p>
                                     Drivers form the core staff of our operations. They effort has engineered the revolution of our management systems to date. Knowing the role they play, we have adopted and implemented the best human resource management process to ensure we identify, recruit, train and maintain the best drivers in our operations
                                     We continue to ensure skills enhancement through regular refresher training, coaching and road monitoring for corrective actions. We have developed clear and precise driver manuals which are issued to them for continuous learning.
                                     We pride ourselves as being one of the most human centred transporters that is taking the lead in implementing driver pro-policies.
                                     We have implemented one of the best driver reward systems that recognises compliance and performance whilst applying transparent sanctions regimes to non-conformance.
                                    </p>
                                )}
                            </div>
                            <div className="section1" onClick={() => toggleSection('customer')}>
                                <h3>CORPORATE STAFF {renderIcon('customer')}</h3>
                                {expandedSection === 'customer' && (
                                    <p>         
                                    KPCL is managed by a very experienced and astute team of professionals and is easily regarded as the most efficient and professionally oriented private bulk transporting company in the industry. KPCL has a qualified, staff strength of over 200 out of which 62 are committed, professionally trained drivers. It has a sound corporate recruitment and employment policy, which underlines the extremely high level of confidence and motivation amongst its staff.
                                    KPCL enjoys the reputation of being the most skilled, literate and numerate organization in terms of driver employment, within the industry. This has culminated in yielding, the lowest driver turnover yet. KPCL attributes this phenomenon to its comprehensive staff training programmes.
                                    We operate an open recruitment process without prejudice to gender, religion, political and ideological inclinations.
                                    We continue to train our staff for operational excellence and providing them with the best management support to perform. We have a bottom-up management process which elicit contributions from all levels of management.
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="quick-links1">
                        <h2>Quick Links</h2>
                        <a onClick={() => handleNavigation('/about')} className="quick-link1" >Our Story</a>
                        <a onClick={() => handleNavigation('/ourteam')} className="quick-link1">Management Team</a>
                        
                    </div>
                </div>
            </div>
            <Partners/>

            {/* Vertical Icons */}
            <VerticalIcons/>

            <Footer/>
        </div>
    );
};

export default AboutPage;
