import React from 'react';
import '../news/News.css';

const newsItems = [
  "Kodson Plus Company ltd is the only Oil and Gas company who has Won the oil and gas awards three times in a row ",
  "2021 Kodson plus won three (3) different award category which includes (1): Oil and Gas Logistics company of the year",
];

const NewsTicker = () => {
  return (
    <div className="news-ticker-container">
      <div className="news-header">NEWS |</div>
      <div className="news-ticker">
        <div className="news-items">
          {newsItems.map((news, index) => (
            <div key={index} className="news-item">{news}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsTicker;
